import { pageUrls } from '@constants';
import {
  filterRouterService,
  getCompositeFastLink,
  getCoursesPath,
  getFastLinkHref,
  getFilterOptionValueSelector,
  getFilters,
  pushDataLayer,
  urlService,
  useFastLinksContext,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RowItemContainerProps } from './rowItem.model';
import { ImageStyled, RowItemStyled, TitleStyled } from './rowItem.styled';
import { FilterEvent, getTabFilterEventData } from '@features/common/filters';

export const RowItemContainer = ({
  item,
  itemIcon,
  itemColor,
  filter,
  handleClick,
}: RowItemContainerProps): ReactElement => {
  const router = useRouter();
  const filters = useSelector(getFilters);
  const fastLinks = useFastLinksContext();
  const filterIsActive = useSelector(
    getFilterOptionValueSelector(filter.slug, item.slug)
  );

  const handleClickItem = (): void => {
    const catalogPath = getCoursesPath(router.query);
    const newQuery = filterRouterService.getNewRouteTab(
      {
        ...router,
        query: filterRouterService.getNewRouteFromFilters(router, filters),
      },
      filter.slug,
      item.slug
    );
    const fastLink = getCompositeFastLink(fastLinks, newQuery);

    pushDataLayer(
      getTabFilterEventData(
        FilterEvent.constructorFiltersMain,
        filter.slug,
        item.slug
      )
    );

    handleClick && handleClick();

    if (fastLink) {
      router.push(
        getFastLinkHref(fastLink.slug, pageUrls.courses, newQuery),
        undefined,
        {
          scroll: false,
        }
      );
    } else {
      router.push(
        urlService.createUrlFromPathAndQuery(catalogPath, router, newQuery),
        undefined,
        { scroll: false }
      );
    }
  };

  return (
    <RowItemStyled
      onClick={handleClickItem}
      isActive={filterIsActive}
      itemColor={itemColor}
    >
      <TitleStyled>{item.value}</TitleStyled>
      {itemIcon && <ImageStyled>{itemIcon}</ImageStyled>}
    </RowItemStyled>
  );
};
