import { pageUrls } from '@constants';
import {
  filterRouterService,
  getCompositeFastLink,
  getCoursesPath,
  getFastLinkHref,
  getFilters,
  urlService,
  useFastLinksContext,
  useTranslations,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../../../../coursesPage';
import { DefaultIconComponent } from '../../tabsIcons';
import { ImageStyled, RowItemStyled, TitleStyled } from '../rowItem';
import { RowItemDefaultContainerProps } from './rowItemDefault.model';
import { useSelector } from 'react-redux';

export const RowItemDefaultContainer = ({
  filter,
  handleClick,
}: RowItemDefaultContainerProps): ReactElement => {
  const router = useRouter();
  const translations = useTranslations();
  const filters = useSelector(getFilters);
  const {
    additionalColors: { filtersAccentColor },
  } = useCoursesPageContext();
  const fastLinks = useFastLinksContext();
  const catalogPath = getCoursesPath(router.query);
  const isActive = !filter.options.some((option) => option.checked);

  const handleClickItem = (): void => {
    const newQuery = filterRouterService.getNewRouteFromFilters(
      router,
      filters
    );

    delete newQuery[filter.slug];

    handleClick && handleClick();

    const fastLink = getCompositeFastLink(fastLinks, newQuery);

    if (fastLink) {
      router.push(
        getFastLinkHref(fastLink.slug, pageUrls.courses, newQuery),
        undefined,
        {
          scroll: false,
        }
      );
    } else {
      router.push(
        urlService.createUrlFromPathAndQuery(catalogPath, router, newQuery),
        undefined,
        { scroll: false }
      );
    }
  };

  return (
    <RowItemStyled
      isActive={isActive}
      onClick={handleClickItem}
      itemColor={filtersAccentColor}
    >
      <TitleStyled>{translations?.all_courses}</TitleStyled>
      <ImageStyled>
        <DefaultIconComponent fillColor={filtersAccentColor} />
      </ImageStyled>
    </RowItemStyled>
  );
};
