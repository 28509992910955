import { NextPageContext } from 'next';
import { getCoursesPath } from '../../features';
import { getCompositeFastLink, getFastLinkHref } from './fastLinks.helper';
import { FastLinkModel } from './fastLinks.model';
import { Nullable, QueryParams } from '../../models';
import { filterRouterService } from '../filters';

export const fastLinkRedirectMiddleware = (
  context: NextPageContext,
  fastLinks: Nullable<FastLinkModel[]>
): void => {
  if (fastLinks && !context.query[QueryParams.discountProgram]) {
    const coursesPath = getCoursesPath(context.query);
    const newQuery = filterRouterService.removeUnusedParams({
      ...context.query,
    });
    const fastLink = getCompositeFastLink(fastLinks, newQuery);

    if (fastLink) {
      context?.res?.writeHead(301, {
        Location: getFastLinkHref(fastLink.slug, coursesPath, context.query),
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      });
      context?.res?.end();
    }
  }
};
