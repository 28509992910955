import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from '../../../../hooks';
import { IconArrowComponent } from '../../../icons';
import { MultiSelectProps, SelectOptionType } from './select.model';
import {
  AllSelectedStyled,
  SelectEmptySearchStyled,
  SelectStyled,
  SelectStyledArrow,
  SelectStyledButton,
  SelectStyledText,
} from './select.styled';
import { SelectDropdown } from './selectDropdown';
import { SelectOption } from './selectOptions/selectOption.container';
import { FilterOptionModel, filterOptionsByTerm } from '../../../../catalog';
import { useTranslations } from '../../../translations';
import { InputColors } from '../../../../styles';
import { FloatingPlaceholderStyled } from '../../floatingPlaceholder';
import { SelectSearchContainer } from './selectSearch';
import { Nullable } from '../../../../models';

export const MultiSelect = ({
  name,
  options,
  placeholder,
  value,
  onChange,
  disabled = false,
  allSelection = false,
  selectAllOptionLabel = 'Выбрать все',
  bordered = true,
  activeHovered = true,
  directionOpenTop = false,
  withSearch = false,
  color = InputColors.white,
  floatingPlaceholder = true,
  hasError,
}: MultiSelectProps): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();
  const filterDropdownRef = useRef<Nullable<HTMLDivElement>>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredOptions, setFilteredOptions] =
    useState<SelectOptionType[]>(options);

  const selectAllOption = useMemo<SelectOptionType>(
    () => ({
      key: 'selectAll',
      value: selectAllOptionLabel,
    }),
    []
  );

  const selectedOptionsCount = useMemo(() => value.length, [value.length]);

  const allSelected = useMemo(
    () => options.length === selectedOptionsCount,
    [options.length, selectedOptionsCount]
  );

  const selectedValue = useMemo(() => {
    const valueString = value.map((opt) => opt.value).join(', ');

    if (allSelected && allSelection) {
      return (
        <>
          {selectAllOption.value}{' '}
          <AllSelectedStyled>({valueString})</AllSelectedStyled>
        </>
      );
    } else {
      return valueString;
    }
  }, [value, allSelected]);

  const setValue = useCallback(
    (newOptions: SelectOptionType[]) => {
      if (name && onChange) {
        onChange(name, newOptions);
      }
    },
    [name]
  );

  const handleToggleDropdown = useCallback((): void => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const handleSelect = useCallback(
    (option: SelectOptionType): void => {
      const selectedOption = value.find((opt) => opt.key === option.key);

      if (selectedOption) {
        setValue(value.filter((opt) => opt.key !== selectedOption.key));
      } else {
        setValue([...value, option]);
      }
    },
    [value, name]
  );

  const handleSelectAll = useCallback(() => {
    setValue(allSelected ? [] : options);
  }, [options, allSelected, value]);

  useEffect(() => {
    const filtered = filterOptionsByTerm(
      searchTerm,
      options as unknown as FilterOptionModel[]
    );
    setFilteredOptions(filtered as unknown as SelectOptionType[]);
  }, [searchTerm, options]);

  const selectSearch: Nullable<ReactElement> = withSearch ? (
    <SelectSearchContainer
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
    />
  ) : null;

  const SelectedLabelComponent = (): ReactElement => {
    const activePlaceholder = floatingPlaceholder ? '' : placeholder;
    const text = selectedOptionsCount === 0 ? activePlaceholder : selectedValue;

    return (
      <SelectStyledText isDefaultValue={!selectedOptionsCount}>
        {text}
      </SelectStyledText>
    );
  };

  const isActiveFloatingPlaceholder = Boolean(selectedOptionsCount);

  return (
    <SelectStyled
      ref={filterDropdownRef}
      color={color}
      disabled={disabled}
      bordered={bordered}
      hasError={hasError}
    >
      {floatingPlaceholder ? (
        <FloatingPlaceholderStyled
          hasError={hasError}
          htmlFor={name}
          children={placeholder}
          active={isActiveFloatingPlaceholder}
        />
      ) : null}

      <SelectStyledButton onClick={handleToggleDropdown} />
      <SelectedLabelComponent />
      <SelectStyledArrow isOpened={isOpened}>
        <IconArrowComponent
          strokeColor={
            isOpened
              ? theme.COLOR.TEXT_DARK_COLOR
              : theme.COLOR.TEXT_LIGHT_COLOR
          }
        />
      </SelectStyledArrow>
      {isOpened && (
        <SelectDropdown
          ref={filterDropdownRef}
          handleOutsideClick={handleToggleDropdown}
          directionOpenTop={directionOpenTop}
          search={selectSearch}
        >
          {allSelection && filteredOptions.length ? (
            <SelectOption
              key={selectAllOption.key}
              option={selectAllOption}
              active={allSelected}
              onClick={handleSelectAll}
              multi
              activeHovered={activeHovered}
            />
          ) : null}

          {filteredOptions.length
            ? filteredOptions.map((option) => (
                <SelectOption
                  key={option.key}
                  option={option}
                  active={!!value.find((opt) => opt.key === option.key)}
                  onClick={handleSelect}
                  multi
                  activeHovered={activeHovered}
                />
              ))
            : null}

          {withSearch && !filteredOptions.length && (
            <SelectEmptySearchStyled>
              {translations.request_not_found}
            </SelectEmptySearchStyled>
          )}
        </SelectDropdown>
      )}
    </SelectStyled>
  );
};
