import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@store/store.hooks';
import {
  CommonEventPositions,
  CommonEventTypes,
  CourseModel,
  filterRouterService,
  getCompositeFastLink,
  getCoursesPath,
  getFastLinkHref,
  getFilters,
  getVendors,
  handleOpenPopup,
  IframeInitiator,
  logger,
  Nullable,
  PopupsEnum,
  QueryParams,
  requestClosePopup,
  urlService,
  useFastLinksContext,
  useUser,
} from '@lerna-core';
import { ListFormsContainer } from '../coursesList';
import { useCoursesPageContext } from '../coursesPage';
import {
  CourseActiveContextModel,
  CourseActiveProviderProps,
} from './coursesActive.model';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { useCoursesContext } from '@features/courses';
import { useSelector } from 'react-redux';
import { useCoursesByTitleContext } from '@features/courses/coursesByTitleList';
import { pageUrls } from '@constants';

const CoursesActiveContext =
  React.createContext<Nullable<CourseActiveContextModel>>(null);

export const CourseActiveContextProvider = ({
  children,
  selectedCourse,
}: CourseActiveProviderProps): ReactElement => {
  const [course, setCourse] = useState<Nullable<CourseModel>>(null);
  const [courseIndex, setCourseIndex] = useState<Nullable<number>>(null);

  const { discountProgram } = useCoursesPageContext();
  const { courses } = useCoursesContext();
  const fastLinks = useFastLinksContext();
  const coursesByTitle = useCoursesByTitleContext();
  const vendors = useSelector(getVendors);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const iframeInitiator = router.query[
    QueryParams.initiator
  ] as IframeInitiator;
  const discountProgramColors = iframeInitiator
    ? undefined
    : discountProgram?.colors;
  const { user } = useUser();

  const catalogPath = getCoursesPath(router.query);
  const filters = useSelector(getFilters);

  const setNumericId = (numericId: string): void => {
    const fastLink = getCompositeFastLink(
      fastLinks,
      filterRouterService.getNewRouteFromFilters(router, filters)
    );
    const newQuery = {
      ...router.query,
      [QueryParams.numericId]: numericId,
    };

    if (fastLink) {
      router.push(
        getFastLinkHref(fastLink.slug, pageUrls.courses, newQuery),
        undefined,
        {
          shallow: true,
        }
      );
    } else {
      router.push(
        urlService.createUrlFromPathAndQuery(catalogPath, router, newQuery),
        undefined,
        { shallow: true }
      );
    }
  };

  const removeNumericId = (): void => {
    const fastLink = getCompositeFastLink(
      fastLinks,
      filterRouterService.getNewRouteFromFilters(router, filters)
    );
    const newQuery = { ...router.query };
    delete newQuery[QueryParams.numericId];

    if (fastLink) {
      router.push(
        getFastLinkHref(fastLink.slug, pageUrls.courses, newQuery),
        undefined,
        {
          shallow: true,
        }
      );
    } else {
      router.push(
        urlService.createUrlFromPathAndQuery(catalogPath, router, newQuery),
        undefined,
        { shallow: true }
      );
    }
  };

  const handleSetActiveCourse = (
    course: CourseModel,
    courseIndex: number
  ): void => {
    setCourse(course);
    setCourseIndex(courseIndex);
  };

  const handleResetActiveCourse = (): void => {
    setCourse(null);
    setCourseIndex(null);
  };

  useEffect(() => {
    const numericId = router.query[QueryParams.numericId];
    if (numericId) {
      let activeCourse: Nullable<CourseModel> = null;

      switch (true) {
        case selectedCourse?.numericId == numericId:
          activeCourse = selectedCourse;
          break;
        default: {
          const course = courses.data.find(
            (course) => course.numericId == numericId
          );

          if (course) {
            activeCourse = course;
          }

          const courseByTitle = coursesByTitle.data.find(
            (course) => course.numericId == numericId
          );
          if (courseByTitle) {
            activeCourse = courseByTitle;
          }
          break;
        }
      }

      if (activeCourse) {
        handleSetActiveCourse(activeCourse, 0);

        orderFormPushDataLayer(
          OrderFormEvents.formEnrollOpen,
          CommonEventTypes.coursesEvents,
          CommonEventPositions.coursesPageList,
          activeCourse,
          vendors,
          0
        );
        dispatch(
          handleOpenPopup(
            !!user
              ? PopupsEnum.userOrderFormPopup
              : PopupsEnum.guestOrderFormPopup,
            CommonEventPositions.coursesPageList
          )
        );
      }
    } else {
      dispatch(
        requestClosePopup(
          !!user
            ? PopupsEnum.userOrderFormPopup
            : PopupsEnum.guestOrderFormPopup
        )
      ).then(handleResetActiveCourse);
    }
  }, [
    router.query[QueryParams.numericId],
    router.query[QueryParams.promoCode],
  ]);

  const context = {
    setNumericId,
    removeNumericId,
  };

  return (
    <CoursesActiveContext.Provider value={context}>
      {children}
      <ListFormsContainer
        course={course}
        courseIndex={courseIndex}
        discountProgramColors={discountProgramColors}
      />
    </CoursesActiveContext.Provider>
  );
};

export const useCourseActiveContext = (): CourseActiveContextModel => {
  const value = useContext(CoursesActiveContext);

  if (!value) {
    logger.error(
      '[ERROR]: CourseActive: you cannot use context without a provider'
    );

    return {} as CourseActiveContextModel;
  }

  return value;
};
